
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationGithubInstall'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  error = ''
  loading = true

  get code () {
    return this.$queryValue('code')
  }

  get installId () {
    return parseInt(this.$queryValue('installation_id') || '')
  }

  get state () {
    return decodeURIComponent(this.$queryValue('state') || '')
  }

  get organizationId () {
    return this.state?.split('_')[0]
  }

  get redirectPath () {
    return this.state?.split('_')[1] || '/'
  }

  mounted () {
    analytics.organizationGithubInstallScreen.track(this, {
      organizationId: [this.organizationId]
    })

    if (this.organizationId) {
      this.auth()
    } else {
      this.$router.push(this.redirectPath)
    }
  }

  async auth () {
    try {
      if (!this.code) {
        throw new Error('GitHub code was not found')
      }
      if (!this.organizationId) {
        throw new Error('Organization id was not found')
      }
      if (!this.installId) {
        throw new Error('Installation id was not found')
      }

      await this.organizationModule.githubInstallCreate({
        code: this.code,
        githubInstallId: this.installId,
        organizationId: this.organizationId
      })

      analytics.organizationGithubInstallCreate.track(this, {
        organizationId: [this.organizationId]
      })

      this.$router.push(this.redirectPath)
    } catch (err: any) {
      this.error = err.body?.message || err.message
      throw err
    } finally {
      this.loading = false
    }
  }
}
